import {
  ApiAuthProvider,
  ApiRequestSettings,
  getDefaultHeaders,
  JsonApiResourceTypes,
  makeJsonApiRequest,
  serializeJsonApiRequest,
} from "./apiHelpers";
import { convertUsdStringToNumber } from "./sharedStringHelpers";

export type SearchEscrowAccountFunc = (
  p: EscrowSearchParams,
) => Promise<{
  meta: any;
  links: any;
  escrowAccounts: any;
}>;

export type FetchEscrowAccountFunc = (
  id: string,
) => Promise<{
  escrowAccount: any;
  rest: {
    meta: any;
    links: any;
  };
}>;

export type CreateInboundPaymentRequestFunc = (
  p: any,
) => Promise<{
  meta: any;
  links: any;
  requestType: string;
  requestData: any;
}>;

export async function fetchCurrentUser(requestSettings: ApiRequestSettings) {
  const jsonApiResponse = await makeJsonApiRequest(
    getHrefForAuthProvider(requestSettings, {
      [ApiAuthProvider.LegacyEarnnest]: "/inbound/api/users/current",
      [ApiAuthProvider.Auth0]: "/api/json/users/current",
    }),
    {
      method: "get",
      headers: { ...getDefaultHeaders(requestSettings) },
    },
  );
  const { data: user, ...rest } = jsonApiResponse.body;
  return { user, ...rest };
}

export async function updateUser(
  requestSettings: ApiRequestSettings,
  userId: string,
  attributes: any,
) {
  const jsonApiResponse = await makeJsonApiRequest(
    getHrefForAuthProvider(requestSettings, {
      [ApiAuthProvider.LegacyEarnnest]: `/inbound/api/users/${userId}`,
      [ApiAuthProvider.Auth0]: `/api/json/users/${userId}`,
    }),
    {
      method: "put",
      headers: { ...getDefaultHeaders(requestSettings) },
      body: serializeJsonApiRequest(
        {
          type: JsonApiResourceTypes.User,
          id: userId,
          ...attributes,
        },
        { excludeAsRelationship: ["roles"] },
      ),
    },
  );
  const { data: user, ...rest } = jsonApiResponse.body;
  return { user, ...rest };
}

export type EscrowSearchParams = {
  state: string;
  query: string;
};

export async function searchEscrowAccounts(
  requestSettings: ApiRequestSettings,
  { state, query }: EscrowSearchParams,
) {
  if (!state) {
    throw new Error("State is required to search escrow accounts");
  }

  const search = [
    ["state", state],
    ["query", query],
  ];
  const searchString = search
    .map(([key, value]) => {
      return key + "=" + encodeURIComponent(value);
    })
    .join("&");

  const jsonApiResponse = await makeJsonApiRequest(
    getHrefForAuthProvider(requestSettings, {
      [ApiAuthProvider.LegacyEarnnest]: `/inbound/api/search?${searchString}`,
      [ApiAuthProvider.Auth0]: `/api/json/escrow-accounts/public-search?${searchString}`,
    }),
    {
      method: "get",
      headers: { ...getDefaultHeaders(requestSettings) },
    },
  );

  const { data: escrowAccounts, ...restBody } = jsonApiResponse.body;
  return { escrowAccounts, ...restBody };
}

export type PaymentRequestSourceParams = {
  source: string;
  sourceCorrelationId: string;
  createdById?: string;
  subsource?: string;
};

export async function createInboundPaymentRequest(
  requestSettings: ApiRequestSettings,
  {
    source,
    sourceCorrelationId: sourceId,
    createdById,
    subsource,
  }: PaymentRequestSourceParams,
  values: any,
) {
  const {
    amount,
    remitterFirstName,
    remitterLastName,
    remitterEmail,
    remitterPhone,
    requestorEmail,
    propertyAddress: addressLine1,
    propertyAddress2: addressLine2,
    propertyCity: city,
    propertyState: stateOrRegion,
    propertyZip: postalCode,
    selectedEscrowAccount,
    emails,
  } = values;

  const jsonApiResponse = await makeJsonApiRequest(
    getHrefForAuthProvider(requestSettings, {
      [ApiAuthProvider.LegacyEarnnest]: "/inbound/api/request",
      [ApiAuthProvider.Auth0]: "/api/json/inbound_request",
    }),
    {
      method: "post",
      headers: {
        ...getDefaultHeaders(requestSettings),
        "x-portal-origin": window.location.origin,
      },
      body: serializeJsonApiRequest(
        {
          type: JsonApiResourceTypes.InboundRequest,
          amount: convertUsdStringToNumber(amount),
          escrowAccountId: selectedEscrowAccount.id,
          source,
          sourceId,
          addressLine1,
          addressLine2,
          city,
          stateOrRegion,
          postalCode,
          remitterFirstName,
          remitterLastName,
          remitterEmail,
          remitterPhone,
          requestorEmail,
          emails,
          createdById,
          subsource,
        },
        {
          excludeAsRelationship: ["emails"],
        },
      ),
    },
  );

  const { data: paymentRequest, ...rest } = jsonApiResponse.body;
  const locationHeader = jsonApiResponse.headers.get("location");
  const response = paymentRequest
    ? { requestType: "payer", requestData: paymentRequest, ...rest }
    : {
        requestType: "requestor",
        requestData: locationHeader,
        ...rest,
      };
  return response;
}

export async function fetchEscrowAccount(
  requestSettings: ApiRequestSettings,
  id: string,
) {
  const jsonApiResponse = await makeJsonApiRequest(
    `${requestSettings.origin}/api/json/escrow-accounts/${id}`,
    {
      method: "get",
      headers: { ...getDefaultHeaders(requestSettings) },
    },
  );
  const { data: escrowAccount, ...rest } = jsonApiResponse.body;
  return { escrowAccount, rest };
}

function getHrefForAuthProvider(
  requestSettings: ApiRequestSettings,
  paths: { [k in ApiAuthProvider]: string },
) {
  return requestSettings.origin + paths[requestSettings.authProvider];
}
